/**
 * Adaptivetheme takes a pretty heavy handed approach to form styling,
 * this is so you can have decent looking forms without having to do a
 * lot of work. The CSS is included in full, including all positioning,
 * so you can change anything you want.
 */
form {
  margin: 0 0 1.5em;
  padding: 0;
}

.form-item label {
  font-weight: 400;
}

input {
  margin: 0;
  max-width: 100%;
}

input.text,
input.title,
textarea,
select {
  border: 1px solid #ccc;
  margin: 0;
}

/* Focus styles for accessibility and usabiltiy. */
.form-text:focus,
.form-checkbox:focus,
.form-radio:focus,
.form-select:focus {
  border: 1px solid #fc0;
  background: #ffffdf;
}

textarea:focus {
  border: 1px solid #fc0;
  background: #fff;
}

/* Inputs and textarea */
input.text,
input.title {
  padding: 0.5em;
}

input.form-text,
textarea {
  border: 1px solid #ccc;
  height: auto;
  padding: 2px;
}

textarea,
input {
  font: 1em normal Arial, Helvetica, sans-serif;
}

div.resizable-textarea textarea {
  margin-top: 0;
}

/* Misc */
.form-item,
.form-action {
  margin-top: 10px;
  margin-bottom: 10px;
}

.exposed-filters .form-item {
  margin-top: 0;
  margin-bottom: 10px;
}

.form-item textarea.error {
  padding: 2px;
}

.form-item .description,
fieldset .description,
.fieldset-layout-settings dd {
  font-size: 0.846em;
}

/* Required, mark */
span.form-required,
span.marker {}


/* Fieldsets */
fieldset {
  background: transparent;
  border: 1px solid #dadada;
  margin: 10px 0;
  padding: 10px;
}

fieldset fieldset:last-child {
  margin-bottom: 0;
}

.form-type-radio .description,
.form-type-checkbox .description {
  margin-left: 0;
}

*:first-child+html fieldset {
  background-color: transparent;
  background-position: 0 10px;
  padding: 0 10px 10px;
}

*:first-child+html fieldset > .description,
*:first-child+html fieldset .fieldset-wrapper .description {
  padding-top: 10px;
}

fieldset legend {
  display: block;
  font-weight: 400;
  padding: 0 10px 0 0;
}

*:first-child+html fieldset legend,
*:first-child+html fieldset.collapsed legend {
  display: inline;
}

html.js fieldset.collapsed {
  background: transparent;
  padding-top: 5px;
}
/* Form descriptions */
.description {}

/* Search */
#search-block-form {}
#search-block-form > div {}
#search-block-form .container-inline{}
#edit-search-block-form-1-wrapper {}
#search-block-form label {}
#search-block-form input {}
#search-block-form .form-submit {}
#search-block-form .form-text {}

/* Advanced search form */
fieldset#edit-advanced fieldset {
  float: left;
  margin: 0 10px 10px 0;
}

fieldset#edit-advanced fieldset .fieldset-legend {
  background: none;
  padding-left: 1em;
}

.advanced-search-submit {
  clear: both;
}

.search-advanced {
  .form-item-type-resource,
  .form-item-type-book {
    display: none;
  }
}

.search-advanced .criterion {
  float: none;
  margin: 0 5px;
}

#edit-keywords-fieldset .form-item:first-child {
  margin-top: 5px;
}

#search-form {
  margin: 10px 0;
}

#search-form > div {}
#search-form .container-inline {}
#search-form label {}
#search-form input {}
#search-form .form-item {}
#search-form .form-submit {}

/* User login */
#user-login-form ul {
  margin: 0;
  padding: 0;
}

#user-login-form li.openid-link,
#user-login-form li.user-link {
  text-align: center;
}

#user-login ul {
  margin: 0 0 5px;
}

#user-login ul li {
  margin: 0;
}

#user-login-form .item-list li {
  list-style: none;
  margin: 0;
}

#user-login-form li.openid-link,
#user-login li.openid-link {
  background: none;
}

/* hide lable class - mostly deprecated but could be present in an upgraded theme */
.hide-label label {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  background: none;
}

/* Admin filters, could be redundant, need to test. */
#user-admin-filter ul li,
#article-admin-filter ul li {
  list-style: none;
}

#article-admin-filter ul {
  padding: 0.5em 0;
}

#edit-operation-wrapper select {
  margin: 0.5em;
}

/* Tips overrides */
.field-type-text-long fieldset {
  padding: 10px 0;
}

.field-type-text-long fieldset .form-item {
  margin: 0;
  padding: 0 0 0 15px;
}

fieldset .filter-help {
  padding: 0 15px 5px;
}

.filter-guidelines {
  font-size: 0.923em;
  margin: 0;
  padding: 5px 18px 10px;
}

.tips {
  font-size: 0.857em;
  margin: 0 0 0 15px;
  padding: 0;
}

/* Special case modules */
#devel-switch-user-form {
  margin-bottom: 0;
}
